import ko from 'knockout';
import { Log } from '@/Common/Log';
import { fetch } from '@/Common/fetch';
import { ConsultationTypeEnum } from '@/Common/enums';

export enum StopWordCategory {
    Threadworm = 'Threadworm',
    Vaccination = 'Vaccination',
    Fever = 'Fever',
    Birthmark = 'Birthmark',
    Medicine = 'Medicine',
    Suicide = 'Suicide',
    Painkillers = 'Painkillers',
    UTI = 'UTI',
}

type StopWordsMap = Record<StopWordCategory, StopWordDetails>;

type StopWordDetails = {
    Words: string[];
    WarningTitle: string;
    WarningMessage: string;
    WarningMessageForEmailConsultation?: string;
};

export enum InputType {
    Subject,
    Message,
}

export class StopWordChecker {
    private isEmailConsultation: KnockoutObservable<boolean>;
    private shortestStopWordLength: KnockoutObservable<number | null> =
        ko.observable(null);
    public stopWords: KnockoutObservable<StopWordsMap> | null =
        ko.observable(null);

    public stopWordWarningTitleForSubject: KnockoutObservable<string> =
        ko.observable('');
    public stopWordWarningMessageForSubject: KnockoutObservable<string> =
        ko.observable('');
    public matchedStopWordForSubject: KnockoutObservable<string> =
        ko.observable('');
    public stopWordWarningTitleForMessage: KnockoutObservable<string> =
        ko.observable('');
    public stopWordWarningMessageForMessage: KnockoutObservable<string> =
        ko.observable('');
    public matchedStopWordForMessage: KnockoutObservable<string> =
        ko.observable('');
    public showSubjectWarning: KnockoutObservable<boolean> =
        ko.observable(false);
    public showMessageWarning: KnockoutObservable<boolean> =
        ko.observable(false);

    public closeSubjectWarning(): void {
        this.showSubjectWarning(false);
    }

    public closeMessageWarning(): void {
        this.showMessageWarning(false);
    }

    constructor(
        consultationType: ConsultationTypeEnum,
        isEmailConsultation: boolean = false,
    ) {
        this.isEmailConsultation = ko.observable(isEmailConsultation);
        this.getStopWords(consultationType);
    }

    private getStopWords = async (
        consultationType: ConsultationTypeEnum,
    ): Promise<void> => {
        try {
            const response: StopWordsMap = await fetch(
                `/api/consultations/stop-words?consultationType=${consultationType}`,
                'GET',
            );

            if (!response) return;

            this.stopWords = ko.observable(response);

            let shortestWordLength: number | null = null;
            Object.values(response).forEach((details) => {
                details.Words.forEach((word: string) => {
                    if (
                        !shortestWordLength ||
                        word.length < shortestWordLength
                    ) {
                        shortestWordLength = word.length;
                    }
                });
            });
            this.shortestStopWordLength(shortestWordLength);
        } catch (error) {
            Log.error('Error when fetching stop words', error);
            return null;
        }
    };

    public checkForStopWords = (
        messageText: string | null,
        inputType: InputType = InputType.Subject,
    ): void => {
        if (this.stopWords() === null) return;
        if (this.shortestStopWordLength() === null) return;

        const lastWord = messageText
            ?.split(' ')
            .pop()
            ?.replace('.', ' ')
            .trim()
            .toLowerCase();
        if (!lastWord) return;

        // Only check if length of the last word makes matches possible
        if (
            lastWord.length <
            this.shortestStopWordLength() -
                this.getMaxLevenshteinDistance(this.shortestStopWordLength())
        )
            return;

        const maxDistance = this.getMaxLevenshteinDistance(lastWord.length);

        for (const [category, details] of Object.entries(this.stopWords())) {
            for (const stopWord of details.Words) {
                // Only check if length of stop word is within maxDistance
                if (stopWord.length > lastWord.length + maxDistance) continue;
                if (stopWord.length < lastWord.length - maxDistance) continue;

                // Perform regular string matching if maximum allowed distance is 0
                if (maxDistance === 0) {
                    if (stopWord.toLowerCase() === lastWord) {
                        this.handleStopWordMatch(category, stopWord, inputType);
                    }
                    continue;
                }

                // If some fuzziness is allowed, use Levenshtein
                const distance = this.getLevenshteinDistance(
                    lastWord,
                    stopWord,
                    maxDistance,
                );
                if (distance > maxDistance) continue;

                this.handleStopWordMatch(category, stopWord, inputType);
                break;
            }
        }
    };

    private handleStopWordMatch(
        category: string,
        matchedStopWord: string,
        inputType: InputType,
    ): void {
        const stopWordCategory = this.stopWords()[category as StopWordCategory];

        const warningTitle = stopWordCategory.WarningTitle;
        if (!warningTitle) {
            Log.error(
                `Could not find Warning Title for ${category}. Update StopWords_Resource`,
            );
            return;
        }

        const specialWarningForEmailConsultation = this.isEmailConsultation()
            ? stopWordCategory?.WarningMessageForEmailConsultation
            : null;
        const warningMessage =
            specialWarningForEmailConsultation ??
            stopWordCategory.WarningMessage;

        if (!warningMessage) {
            Log.error(
                `Could not find Warning Message for ${category}. Update StopWords_Resource`,
            );
            return;
        }

        // Log the fact that we caught a stop word
        Log.info(
            `Caught StopWord: ${matchedStopWord} (in StopWord category: ${category})`,
        );

        const capitalizedStopWord =
            matchedStopWord.charAt(0).toUpperCase() + matchedStopWord.slice(1);

        if (inputType === InputType.Subject) {
            this.matchedStopWordForSubject(capitalizedStopWord);
            this.stopWordWarningTitleForSubject(warningTitle);
            this.stopWordWarningMessageForSubject(warningMessage);
            this.showSubjectWarning(true);
        }

        if (inputType === InputType.Message) {
            this.matchedStopWordForMessage(capitalizedStopWord);
            this.stopWordWarningTitleForMessage(warningTitle);
            this.stopWordWarningMessageForMessage(warningMessage);
            this.showMessageWarning(true);
        }

        // We don't want to show (or look for) the same warning twice
        delete this.stopWords()[category as keyof typeof StopWordCategory];
    }

    private getLevenshteinDistance = (
        input: string,
        target: string,
        maxDistance: number,
    ): number => {
        if (input == null || target == null || maxDistance == null) {
            throw new Error('Arguments cannot be null');
        }

        // Initialize the matrix
        const matrix: number[][] = Array.from(
            { length: input.length + 1 },
            (_, i) =>
                Array.from({ length: target.length + 1 }, (_, j) =>
                    i === 0 ? j : j === 0 ? i : 0,
                ),
        );

        // Loop through each character and calculate the minimum edit distance
        for (let i = 1; i <= input.length; i++) {
            let minDistanceInRow = Infinity;

            for (let j = 1; j <= target.length; j++) {
                const charactersMatch = input[i - 1] === target[j - 1];
                const cost = charactersMatch ? 0 : 1;

                const substitutionCost = matrix[i - 1][j - 1] + cost;
                const deletionCost = matrix[i - 1][j] + 1;
                const insertionCost = matrix[i][j - 1] + 1;

                matrix[i][j] = Math.min(
                    substitutionCost,
                    deletionCost,
                    insertionCost,
                );

                minDistanceInRow = Math.min(minDistanceInRow, matrix[i][j]);
            }

            // Exit early if the minimum distance in the current row exceeds maxDistance
            if (minDistanceInRow > maxDistance) {
                return maxDistance + 1; // Return a value greater than maxDistance to indicate early exit
            }
        }

        return matrix[input.length][target.length];
    };

    private getMaxLevenshteinDistance = (wordLength: number): number => {
        return wordLength < 5
            ? 0
            : wordLength < 8
              ? 1
              : wordLength <= 12
                ? 2
                : 3;
    };

    public logIfUserContinuesWithStopWord(): void {
        if (
            !this.matchedStopWordForMessage() &&
            !this.matchedStopWordForSubject()
        ) {
            return;
        }

        let logMessage =
            'Booking flow continued after StopWord warning was shown: \n';

        if (this.matchedStopWordForSubject()) {
            logMessage += `- StopWord in Subject: ${this.matchedStopWordForSubject()}. \n`;
        }

        if (this.matchedStopWordForMessage()) {
            logMessage += `- StopWord in Message: ${this.matchedStopWordForMessage()}. \n`;
        }

        Log.info(logMessage);
    }
}
